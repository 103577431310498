import { ReadonlyURLSearchParams } from 'next/navigation';
import queryString from 'query-string';

export const stringifyUrl = (
  endpoint: string,
  params: queryString.StringifiableRecord
) => {
  return queryString.stringifyUrl(
    { url: endpoint, query: params },
    { skipNull: true }
  );
};

export function decodeQueryParamAsString(param?: string | string[]) {
  return decodeURIComponent(param as string);
}

export function getAllParamsFromSearchParams(
  searchParams: ReadonlyURLSearchParams | null
): {
  [key: string]: string;
} {
  if (!searchParams) return {};

  const params: { [key: string]: string } = {};
  for (const [key, value] of searchParams.entries()) {
    params[key] = decodeQueryParamAsString(value);
  }
  return params;
}

export function getAllParamsFromHref(href: string): Record<string, string> {
  return queryString.parseUrl(href).query as Record<string, string>;
}
