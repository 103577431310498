import { BackgroundColors } from '@/lib/marketing-page/dto/modules/shared';

import { ClassValue } from 'clsx';

export function getBackgroundSectionClassName(
  backgroundColor?: BackgroundColors
): ClassValue {
  return backgroundColor === BackgroundColors.ALMOND
    ? 'bg-almond-99'
    : 'bg-white';
}
