export enum ManagerRoles {
  CommunityManager = 'Community Manager',
  OperationsAssociate = 'Operations Associate',
  AreaManager = 'Area Manager',
  RegionalManager = 'Regional Manager',
  MembershipManager = 'Membership Manager',
  NationalAccountsMembershipManager = 'National Accounts Membership Manager',
  ConferenceServicesManager = 'Conference Services Manager',
  TechnicalAudioVisualManager = 'Technical Audio Visual Manager',
}
