import { getServerConfig } from '@/utils/config';
import { tryFetchToJson } from '@/utils/http-request';

import { LocationPhases, SimpleLocationDto } from './dto';

export async function getNearestLocations(props: {
  locale: string;
  longitude: number;
  latitude: number;
  phases?: LocationPhases[];
  slugs?: string[];
  limit: number;
}): Promise<SimpleLocationDto[]> {
  const { locale, longitude, latitude, slugs, phases, limit } = props;
  const { NEXT_PUBLIC_INVENTORY_API_URL } = getServerConfig();

  const url = `${NEXT_PUBLIC_INVENTORY_API_URL}/discovery/nearest-locations`;
  const body = JSON.stringify({
    longitude,
    latitude,
    slugs,
    phases,
    limit,
  });

  return tryFetchToJson<SimpleLocationDto[]>({
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Accept-Language': locale,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      },
    },
    skipFetchError: true,
  });
}
