import Cookies from 'js-cookie';

export const COOKIE_KEY_MKTO_TRK = '_mkto_trk'; // to remove after marketo is removed
export const COOKIE_KEY_MKTO_REFERRER = '_mkto_referrer'; // to remove after marketo is removed

export const COOKIE_KEY_GCLID = 'gclid';
export const COOKIE_KEY_HUBSPOT_UTK = 'hubspotutk';

export const COOKIE_KEY_UTM_CAMPAIGN = 'utm_campaign';
export const COOKIE_KEY_UTM_SOURCE = 'utm_source';
export const COOKIE_KEY_UTM_MEDIUM = 'utm_medium';
export const COOKIE_KEY_UTM_CONTENT = 'utm_content';
export const COOKIE_KEY_UTM_TERM = 'utm_term';

export const COOKIE_KEY_IND_GEODATA = 'ind_geodata';
export const COOKIE_KEY_IND_CHANNEL = 'ind_channel';
export const COOKIE_KEY_IND_KPI = 'ind_kpi';
export const COOKIE_KEY_IND_AD_GROUP = 'ind_ad_group';
export const COOKIE_KEY_IND_AD_CAMPAIGN = 'ind_ad_campaign';
export const COOKIE_KEY_IND_LS = 'ind_ls';
export const COOKIE_KEY_IND_AUD = 'ind_aud';

export type Utms = {
  source?: string | null;
  medium?: string | null;
  term?: string | null;
  campaign?: string | null;
  content?: string | null;
};

export const COOKIE_KEY_LOCALE = 'NEXT_LOCALE';

export function getCookieByKey(key: string) {
  return Cookies.get(key);
}

export function setCookieByKey(key: string, value: string) {
  return Cookies.set(key, value);
}

export function removeCookie(key: string) {
  return Cookies.remove(key);
}

export function removeAllCookies() {
  const cookies = Cookies.get();

  for (const key of Object.keys(cookies)) {
    Cookies.remove(key);
  }
}

export function getTrackingCookies() {
  const marketoCookie = getCookieByKey(COOKIE_KEY_MKTO_TRK);
  const hubspotutk = getCookieByKey(COOKIE_KEY_HUBSPOT_UTK);

  const utmCampaign = getCookieByKey(COOKIE_KEY_UTM_CAMPAIGN);
  const utmSource = getCookieByKey(COOKIE_KEY_UTM_SOURCE);
  const utmMedium = getCookieByKey(COOKIE_KEY_UTM_MEDIUM);
  const utmContent = getCookieByKey(COOKIE_KEY_UTM_CONTENT);
  const utmTerm = getCookieByKey(COOKIE_KEY_UTM_TERM);
  const gclid = getCookieByKey(COOKIE_KEY_GCLID);
  const referrer = getCookieByKey(COOKIE_KEY_MKTO_REFERRER);

  const indChannel = getCookieByKey(COOKIE_KEY_IND_CHANNEL);
  const indKpi = getCookieByKey(COOKIE_KEY_IND_KPI);
  const indAdGroup = getCookieByKey(COOKIE_KEY_IND_AD_GROUP);
  const indAdCampaign = getCookieByKey(COOKIE_KEY_IND_AD_CAMPAIGN);
  const indLs = getCookieByKey(COOKIE_KEY_IND_LS);
  const indAud = getCookieByKey(COOKIE_KEY_IND_AUD);

  return {
    marketoCookie,
    hubspotutk,
    utmCampaign,
    utmSource,
    utmMedium,
    utmContent,
    utmTerm,
    gclid,
    referrer,
    indChannel,
    indKpi,
    indAdGroup,
    indAdCampaign,
    indLs,
    indAud,
  };
}
