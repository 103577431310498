import { getServerConfig } from '@/utils/config';
import { tryFetchToJson } from '@/utils/http-request';

import { LocationPhases, SimpleLocationDto } from './dto';

export async function getNearestLocation(props: {
  locale: string;
  longitude: number;
  latitude: number;
  withOnlineAccessCheckout?: boolean;
  phases?: LocationPhases[];
  slugs?: string[];
}): Promise<SimpleLocationDto | undefined> {
  const {
    locale,
    longitude,
    latitude,
    slugs,
    phases,
    withOnlineAccessCheckout,
  } = props;
  const { NEXT_PUBLIC_INVENTORY_API_URL } = getServerConfig();

  const url = `${NEXT_PUBLIC_INVENTORY_API_URL}/discovery/nearest-location`;
  const body = JSON.stringify({
    longitude,
    latitude,
    slugs,
    phases,
    withOnlineAccessCheckout,
  });

  return tryFetchToJson<SimpleLocationDto>({
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Accept-Language': locale,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      },
    },
    skipFetchError: true,
  });
}
