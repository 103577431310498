import deepmerge from 'deepmerge';

import { DEFAULT_LOCALE } from './constants';

export const UNDEFINED_LOCALE = 'default';

export function getLocale(locale: string = UNDEFINED_LOCALE): string {
  if (locale === UNDEFINED_LOCALE) {
    return DEFAULT_LOCALE;
  }

  return locale;
}

async function getDictionary(locale: string, folderName?: string) {
  if (folderName) {
    return import(`@/dictionaries/${folderName}/${locale}.json`)
      .then((value) => value.default)
      .catch(() =>
        import(`@/dictionaries/${DEFAULT_LOCALE}.json`).then(
          (value) => value.default
        )
      );
  }
  return import(`@/dictionaries/${locale}.json`)
    .then((value) => value.default)
    .catch(() =>
      import(`@/dictionaries/${DEFAULT_LOCALE}.json`).then(
        (value) => value.default
      )
    );
}

async function getDictionaryMergedWithDefault(
  locale: string,
  folderName?: string
) {
  const [defaultLocaleDictionary, useLocaleDictionary] = await Promise.all([
    getDictionary(DEFAULT_LOCALE, folderName),
    getDictionary(locale, folderName),
  ]);

  return deepmerge(defaultLocaleDictionary, useLocaleDictionary);
}

export async function getTranslationMessages(
  locale: string | undefined,
  folderName?: string
) {
  if (!locale || locale === UNDEFINED_LOCALE || locale === DEFAULT_LOCALE) {
    return getDictionary(DEFAULT_LOCALE, folderName);
  }

  return getDictionaryMergedWithDefault(locale, folderName);
}

export function getGeneralMessages(messages: any) {
  return {
    general: messages.general,
    head: messages.head,
    nav: messages.nav,
    footer: messages.footer,
    login: messages.login,
    signup: messages.signup,
    'cookie-banner': messages['cookie-banner'],
    'toast-error': messages['toast-error'],
    'reset-password-email': messages['reset-password-email'],
  };
}
