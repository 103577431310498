import { NextRouter } from 'next/router';

import { getServerConfig } from './config';
import { DEFAULT_LOCALE } from './constants';

export function getCurrentPath({
  path,
  locale,
}: {
  path?: string;
  locale?: string;
}) {
  const host = window.location.protocol + '//' + window.location.host;
  const url =
    locale === DEFAULT_LOCALE ? `${host}${path}` : `${host}/${locale}${path}`;
  return url;
}

export function getBaseCurrentPath({ basePath, locale }: Partial<NextRouter>) {
  return getCurrentPath({ path: basePath, locale });
}
export function getFullCurrentPath({ asPath, locale }: Partial<NextRouter>) {
  return getCurrentPath({ path: asPath, locale });
}

export function getLastIndustriousUrl() {
  const { HOST } = getServerConfig();

  if (typeof window === 'undefined') return HOST;

  return `${HOST}${window.location.pathname}`;
}

export function updateUrlWithoutRedirecting(newUrl: string) {
  window.history.replaceState(
    { ...window.history.state, as: newUrl, url: newUrl },
    '',
    newUrl
  );
}

export function getPathnameFromHref(href: string) {
  return href.includes('#')
    ? href.split('?')[0].split('#')[0]
    : href.split('?')[0];
}
