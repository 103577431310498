import { LocationCalendlyLinksDto } from '@/lib/locations/dto/location-calendly-links.dto';
import { LocationPhases } from '@/lib/locations/dto/location-phases.enum';

import { getTrackingCookies } from './cookies';

export function createUtmCalendly(
  utms: Pick<
    ReturnType<typeof getTrackingCookies>,
    'utmCampaign' | 'utmContent' | 'utmMedium' | 'utmSource' | 'utmTerm'
  >
) {
  const { utmCampaign, utmContent, utmMedium, utmSource, utmTerm } = utms;

  return {
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    utmTerm,
  };
}

export function createPrefillCalendly(props: {
  company?: string;
  firstName?: string;
  email?: string;
  lastName?: string;
  phone?: string;
}) {
  const { email, firstName, lastName, company, phone } = props;

  return {
    email,
    firstName,
    lastName,
    customAnswers: {
      a1: company,
      a2: phone,
    },
  };
}

export function getAppropiateCalendlyLink(props: {
  calendlyLinks: LocationCalendlyLinksDto;
  phase: LocationPhases;
}) {
  const { calendlyLinks, phase } = props;

  if (phase === LocationPhases.Open) {
    return adaptCalendlyLink(calendlyLinks.inPersonTour);
  }

  return adaptCalendlyLink(calendlyLinks.phoneCall);
}

export function adaptCalendlyLink(link: string | null) {
  if (!isUrlValid(link)) {
    return null;
  }
  if (link.includes('https://')) {
    return link;
  }
  return `https://${link}`;
}

function isUrlValid(str: string | null): str is string {
  if (str == null) return false;
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(str);
}
