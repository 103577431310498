import { string } from 'yup';

import { isValidDate } from '../date-utils';

export const stringDay = () =>
  string().test((x) => {
    if (x == null) {
      return true;
    }
    return isValidDate(x) && /^\d{4}-\d{2}-\d{2}$/.test(x);
  });
