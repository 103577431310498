import { LocationManagerDto, ManagerRoles } from '@/lib/locations';

export function mockLocationExperienceTeamMember(
  override: Partial<LocationManagerDto>
): LocationManagerDto {
  return {
    name: 'John Doe',
    email: 'johndo@industriousoffice.com',
    avatarUrl: 'an avatar url',
    role: ManagerRoles.CommunityManager,
    ...override,
  };
}
