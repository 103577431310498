export enum LocationPriceFormat {
  CheapestPerSeat = 'Cheapest Per Seat',
  CheapestOffice = 'Cheapest Office',
}

export type LocationPricesDto = {
  currencyIsoCode: string;
  priceFormat: LocationPriceFormat;
  lowestPrices: {
    dedicatedOffice: LocationPriceItemDto | undefined | null;
    suites: LocationPriceItemDto | undefined | null;
    access: LocationPriceItemDto | undefined | null;
    virtual: LocationPriceItemDto | undefined | null;
    meetingRoom: LocationMeetingRoomPriceItemDto | null;
    coworkingDayPass: LocationCoworkingDayPassPriceItemDto | undefined | null;
  };
};

export type LocationMeetingRoomPriceItemDto = {
  price: number;
};

export type LocationCoworkingDayPassPriceItemDto = {
  price: number;
};

export type LocationPriceItemDto = {
  family: string;
  price: number;
  productSquareFootage: number | null | undefined;
  seatCount: number | null | undefined;
};
