import { createEmptyLocationPrices } from '@/models';
import { tryFetchToJson } from '@/utils/http-request';

import { LocationPricesDto } from './dto';

export async function fetchLocationPricingById(
  id: string
): Promise<LocationPricesDto> {
  const url = `${process.env.NEXT_PUBLIC_PRICING_API_URL}/discovery/locations/${id}/pricings`;

  const locationPricesDto = await tryFetchToJson<LocationPricesDto>({
    fetchParams: {
      input: url,
    },
    onFunctionalError: () => createEmptyLocationPrices(),
    onTechnicalError: () => createEmptyLocationPrices(),
  });

  return locationPricesDto as LocationPricesDto;
}
