import { ImageDto } from './image.dto';
import { VideoDto } from './video.dto';

export enum BackgroundType {
  Image = 'image',
  Video = 'video',
  Color = 'color',
}

export enum BackgroundColors {
  ALMOND = 'almond',
  WHITE = 'white',
}

export type BackgroundImageDto = {
  type: BackgroundType.Image;
  image: ImageDto;
};

export type BackgroundVideoDto = {
  type: BackgroundType.Video;
  video: VideoDto;
};

export type BackgroundColorDto = {
  type: BackgroundType.Color;
  color: BackgroundColors;
};
