import { ImageDto } from './image.dto';

export enum EyebrowType {
  Text = 'text',
  Image = 'image',
}

export type TextEyebrowDto = {
  type: EyebrowType.Text;
  text: string;
};

export type ImageEyebrowDto = ImageDto & {
  type: EyebrowType.Image;
};
