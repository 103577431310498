import * as Sentry from '@sentry/nextjs';

export function captureException(message: string, error?: Error) {
  Sentry.captureException(
    new Error(message, {
      cause: error,
    })
  );
}

export function captureMessage(
  message: string,
  severity?: Sentry.SeverityLevel
) {
  Sentry.captureMessage(message, severity ?? 'info');
}
